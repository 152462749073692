import React from 'react'
import {
    Container,
    Spinner,
    Card,
    CardText,
    CardBody,
    CardHeader,
    Button,
    Row,
    Col,
} from 'reactstrap'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCalendar } from '@fortawesome/free-solid-svg-icons'
import Post from './Post'

class PostsSummary extends React.Component {
    constructor() {
        super()
        this.state = {
            hasLoaded: false,
        }
    }

    componentDidMount() {
        axios.get(global.host + 'posts.php?').then((response) => {
            this.setState({
                post: response.data,
                hasLoaded: true,
            })
        })
    }

    createPostSummary = (posts) => {
        return posts.map((post, index) => (
            <Col lg="6" key={index}>
                <Card style={{ margin: 10 }}>
                    <CardHeader className="card-styling">
                        <h5 className="text-uppercase">{post.title}</h5>
                        <FontAwesomeIcon
                            icon={faCalendar}
                            color="orange"
                        />{' '}
                        {post.createdAt}{' '}
                        <FontAwesomeIcon icon={faUser} color="orange" />{' '}
                        <b>{post.author}</b>
                    </CardHeader>
                    <CardBody>
                        <CardText className="text-secondary">
                            {post.subtitle}
                        </CardText>
                        <Button
                            tag={Link}
                            to={`inlagg?id=${post.id}`}
                            color="primary"
                        >
                            Läs mer
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        ))
    }

    render() {
        const spinner = (
            <div>
                <Spinner
                    style={{
                        width: '3rem',
                        height: '3rem',
                    }}
                    color="primary"
                />
            </div>
        )

        if (!this.state.hasLoaded) {
            return spinner
        } else if (this.state.hasLoaded && this.state.post.length < 1) {
            return <p>Inga inlägg har ännu publicerats.</p>
        } else {
            const summary = this.createPostSummary(this.state.post)
            return (
                <Container>
                    <Row>
                        <Post posts={this.state.post} />
                        {summary}
                    </Row>
                </Container>
            )
        }
    }
}

export default PostsSummary
