import React from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, Input, Row, Col, FormGroup } from "reactstrap";
import { Redirect } from "react-router";
import axios from "axios";

class NewsPublisher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            title: "",
            subTitle: "",
            redirect: false,
            selectedFile: null,
            attachedFiles: []
        };
    }


    onEditorStateChange: Function = (editorState) => {
        console.log(this.state.attachedFiles);

        this.setState({
            editorState,
        });
    };



    onTitleChange = event => {
        this.setState({
            title: event.target.value
        });
    };

    publishPost = () => {
        var contentToSaveInDb = convertToRaw(
            this.state.editorState.getCurrentContent()
        );
        this.setState({
            redirect: true
        });

        axios
            .post(global.host + "submit-post.php", {
                title: this.state.title,
                subtitle: this.state.subTitle,
                author: "Styrelsen",
                attachedFiles: (this.state.attachedFiles !== null ? this.state.attachedFiles : []),
                content: contentToSaveInDb
            },
                {
                    withCredentials: true
                }
            )
            .then(function (response) {
                console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    };

    newsEditor = () => {
        const styling = {
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "lightgrey",
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10
        };
        const content = <Row>
            <Col lg="6">
                <p className="font-weight-bold"> Nytt inlägg </p>
                <FormGroup>
                    <Input
                        type="text"
                        placeholder="Skriv en titel"
                        value={this.state.title}
                        onChange={this.onTitleChange}
                    />
                </FormGroup>
            </Col>
            <Col lg="12">
                <Editor
                    editorState={this.state.editorState}
                    editorStyle={styling}
                    onEditorStateChange={this.onEditorStateChange}
                />
                <Button color="success" onClick={this.publishPost}>
                    {" "}
                    Publicera{" "}
                </Button>
            </Col>
        </Row>;
        return content;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/" />;
        } else {
            return this.newsEditor();
        }

    }
}
export default NewsPublisher;
