import React from 'react'
import {
    Alert,
    Container,
    Button,
    Col,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    Progress,
} from 'reactstrap'
import zxcvbn from 'zxcvbn'
import axios from 'axios'

export default class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            _password: '',
            submitted: false,
            errMsg: '',
            success: '',
            passwordPoints: 80,
        }
    }

    onInput = (event) => {
        var inputId = event.target.id
        var inputValue = event.target.value
        this.setState({
            [inputId]: inputValue,
        })
    }

    passwordScore = () => {
        return zxcvbn(this.state.password).score
    }
    passwordsAreIdentical = () => {
        return this.state.password === this.state._password
    }

    onSubmit = (event) => {
        event.preventDefault()
        axios
            .post(
                global.host + 'register.php',
                {
                    email: this.state.email,
                    password: this.state.password,
                    _password: this.state._password,
                },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        submitted: true,
                        success: false,
                        errMsg: response.data.message,
                    })
                }
                if (response.status === 201) {
                    this.setState({
                        submitted: true,
                        success: true,
                    })
                }
            })
    }
    passwordLevel = () => {
        if (zxcvbn(this.state.password).score >= 3) {
            return this.errMessage(true, 'Lösenordet är säkert.')
        } else {
            return this.errMessage(false, 'Lösenordet är för enkelt.')
        }
    }

    errMessage = (valid, text) => {
        if (valid) {
            return (
                <div className="text-success">
                    <i className="fa fa-check"></i> {' ' + text}
                </div>
            )
        } else {
            return (
                <div className="text-danger">
                    <i className="fa fa-close"></i> {' ' + text}
                </div>
            )
        }
    }

    tryCreateUserMessage = () => {
        return this.errMessage(false, this.state.errMsg)
    }
    userCreatedMessage = () => {
        return (
            <div>
                <Alert color="success">
                    <h4 className="alert-heading">Konto har skapats!</h4>
                    <p>
                        Du kan nu logga in med de uppgifter du tidigare angav.
                    </p>
                </Alert>
            </div>
        )
    }

    render() {
        if (this.state.success) {
            return this.userCreatedMessage()
        } else {
            return (
                <Container>
                    <Row>
                        <Col
                            sm="12"
                            md={{
                                size: 6,
                                offset: 3,
                            }}
                        >
                            <h1> Registrera konto </h1>
                            <Form onSubmit={this.onSubmit}>
                                <FormGroup>
                                    <Label for="exampleEmail">Epost</Label>
                                    <Input
                                        type="email"
                                        value={this.state.email}
                                        onChange={this.onInput}
                                        name="email"
                                        id="email"
                                        placeholder="epost@hotmail.com"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="examplePassword">
                                        Lösenord
                                    </Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={this.onInput}
                                        placeholder="******"
                                    />
                                </FormGroup>
                                {this.passwordLevel()}
                                <Progress
                                    animated
                                    color="info"
                                    value={this.passwordScore()}
                                    max="4"
                                />
                                <FormGroup>
                                    <Label for="examplePassword">
                                        Repetera lösenord
                                    </Label>
                                    <Input
                                        type="password"
                                        name="_password"
                                        id="_password"
                                        onChange={this.onInput}
                                        placeholder="******"
                                    />
                                </FormGroup>

                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={false}
                                >
                                    Submit
                                </Button>
                            </Form>
                            {this.tryCreateUserMessage()}
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}
