import React, { useState } from "react";
import { Alert, Container, FormGroup, Input, Label, Spinner } from "reactstrap";
import AddSigners from "../../Components/AddSigners";
import UploadFile from "../../Components/UploadFile";

export default function UploadFilePage() {
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [response, setResponse] = useState("");
    const [formData, setFormData] = useState({
        signers: [{ first_name: "", last_name: "", address: "" }],
        category: "",
        errors: { signers: [] }

    });
    const [uploading, setUploading] = useState(false);
    const [errorUploading, setErrorUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const categories = [{ "value": "", "helperText": "Välj kategori" },
    { "value": "protokoll", "helperText": "Protokoll" },
    { "value": "andrahandsuthyrning", "helperText": "Andrahandsuthyrning" },
    { "value": "nyckelkvittens", "helperText": "Nyckelkvittens" },
    { "value": "ovrigt", "helperText": "Övrigt" }]

    const handleCategoryChange = event => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const postData = async () => {
        try {
            const data = {
                filename: fileName,
                base64encodedFile: file,
                category: formData.category,
                signers: formData.signers
            };
            console.log(data);

            const response = await fetch(global.host + "file/upload/to-sign/index.php", {
                credentials: 'include', // Pass credentials with the request
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            console.log(JSON.stringify(data));

            const responseData = await response.json();
            if (responseData.success) {
                setUploaded(true);
                setErrorUploading(false)
            }
            setResponse(responseData);
        } catch (error) {
            setErrorUploading(true);
            console.error('Error:', error);
        }
    };


    const handleSubmit = async (e) => {
        console.log("T");
        e.preventDefault();
        const { signers } = formData;
        let errors = [];
        signers.forEach((signer, index) => {
            let signerErrors = {};
            if (!signer.first_name || !signer.last_name || !signer.address) {
                signerErrors = {
                    first_name: !signer.first_name ? "Ett förnamn måste fylla i" : "",
                    last_name: !signer.last_name ? "Ett efternamn måste fylla i" : "",
                    address: !signer.address ? "En epost måste fylla i" : ""
                };
                errors[index] = signerErrors;
            }
        });
        if (errors.length > 0) {
            setFormData({ ...formData, errors });
        } else {
            // Submit form data
            setUploading(true);
            await postData();
            setUploading(false);
            /** Time to send the data to the backend and handle the submit.*/
        }
    };

    if (uploaded) {
        return <Alert color="success" className="text-center"><h3>Filen har laddats upp för signering!</h3> <br />
            Kolla angivna epostaddresser för att signera. <br /><br />
            <b>OBS: </b> Kolla även skräpposten!<br />
        </Alert>
    }


    if (errorUploading) {
        return <Alert color="danger">Något gick fel med uppladdning! <br /> Försök igen senare... Om problemet kvarstår kontakta din administratör.</Alert>
    }

    if (uploading) {
        return (
            <Spinner
                style={{
                    width: '3rem',
                    height: '3rem',
                }}
                color="primary"
            />
        )
    }

    return (
        <Container>
            <UploadFile file={file} setFile={setFile} setFileName={setFileName} />
            <FormGroup className="mt-3">
                <Label>Välj kategori för filen</Label>
                <Input
                    type="select"
                    name="category"
                    value={formData.category}
                    onChange={handleCategoryChange}
                >
                    {categories.map((category, index) => <option key={index} value={category.value}>{category.helperText}</option>)}
                    {/* Add more options as needed */}
                </Input>
            </FormGroup>
            <AddSigners formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} disabled={file === null || formData.category === ''} />
            {fileName}
        </Container>
    );
}
