import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
    Alert,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export default function PostHandler(props) {
    const [posts, setPosts] = useState([])
    const [modal, setModal] = useState(false)
    const [postId, setPostId] = useState(-1)
    const [resMessage, setResMessage] = useState('')

    const toggle = () => setModal(!modal)

    const fetchData = async () => {
        const result = await axios(global.host + 'posts.php', {
            method: 'GET',
        })
        setPosts(result.data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const updateData = () => {
        axios(global.host + 'posts.php', {
            method: 'GET',
        }).then((res) => {
            setPosts(res.data)
        })
    }

    const onClickVerifyDelete = () => {
        axios(global.host + 'posts.php', {
            withCredentials: true,
            method: 'DELETE',
            data: {
                id: postId,
            },
        }).then((res) => {
            setModal(!modal)
            setResMessage(res.data.message)
            updateData()
        })
    }

    const onClickDelete = (postId) => {
        setPostId(postId)
        setModal(!modal)
    }
    return (
        <div>
            <h1> Hantera inlägg</h1>
            {resMessage ? <Alert color="info">{resMessage}</Alert> : ''}

            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id </th>
                        <th>Titel</th>
                        <th>Skapad</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {posts.map((post, row) => (
                        <tr key={row}>
                            <th scope="row">{row + 1}</th>
                            <th scope="row">{post.id}</th>
                            <td>{post.title}</td>
                            <td>{post.createdAt}</td>
                            <td>
                                <Button
                                    color="danger"
                                    id={post.id}
                                    onClick={() => onClickDelete(post.id)}
                                >
                                    {' '}
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Är du säker på att du vill ta bort nyheten?
                </ModalHeader>
                <ModalBody>
                    <b>Id:</b> {postId}
                    <br />
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={onClickVerifyDelete}>
                        Ta bort
                    </Button>{' '}
                    <Button color="primary" onClick={toggle}>
                        Avbryt
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    )
}
