import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import BgImage from '../Files/Home/logo-540x360.jpg'
import { Helmet } from 'react-helmet'

export function Home() {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hem | Brf Lyfta </title>
                <meta
                    name="description"
                    content="Hemsida för Brf Lyfta - en Bostadsrättsförenig i Barkarbystaden."
                />
                <link rel="canonical" href="https://www.brflyfta.se/" />
            </Helmet>
            <Container fluid className="fluid header-box page-title">
                <Container>
                    <Row>
                        <Col xs="12" sm="6">
                            <img
                                className="img-fluid rounded"
                                src={BgImage}
                                alt="FÖRENINGSHUSET"
                            />
                        </Col>
                        <Col sm="6">
                            <h1>Brf Lyfta</h1>
                            <h2>Stockholms län, Järfälla</h2>
                            <p>
                                Välkommen till Brf Lyfta! <br />
                                <br />
                                Bostadsrättsföreningen ligger i Barkarbystaden -
                                ett nytt område som växer fram i det område som
                                tidigare var Barkarby flygfält. Alldeles intill
                                Järvafältet och Barkarby Handelsplats.
                                <br />
                                <br />
                                Om några år kommer detta att vara västra
                                Stockholms nya knutpunkt med 50.000 invånare och
                                10.000 arbetsplatser men redan nu finns allt du
                                behöver – skolor, förskolor, shopping, butiker,
                                restauranger och caféer.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}
