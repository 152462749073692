import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import Arsredovisning from '../Files/Economy/2019-arsredovisning-brf-lyfta.pdf'
import RevisorsUttalande from '../Files/Economy/2019-revisorns-utlatande.pdf'
import Arsredovisning2020 from '../Files/Economy/2020-arsredovisning-brf-lyfta.pdf'
import Arsredovisning2021 from '../Files/Economy/2021-arsredovisning-brf-lyfta.pdf'

export function BottomMenu() {
    return (
        <div>
            <Container className="footer-box">
                <Row>
                    <Col xs="12" sm="3">
                        <h5> Kontaktuppgifter</h5>
                        <ul>
                            <li>
                                <h6>Pantärenden skickas till adressen:</h6>
                                <p>
                                    Fastum AB <br />
                                    Panter <br />
                                    Box 3118 <br />
                                    169 03 SOLNA
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Felanmälan Storholmen</b>
                                </p>
                                <p>

                                    {' '}
                                    <a href="https://www.dwoqdirect.se">
                                        Felanmälan formulär{' '}
                                    </a>
                                </p>

                            </li>
                            <li>
                                {' '}
                                <p>

                                    <a href="mailto:info@dwoqdirect.se">
                                        Felanmälan mejl - info@dwoqdirect.se{' '}
                                    </a>
                                </p>

                            </li>
                            <li>
                                <p>


                                    Personlig service helgfria vardagar mellan
                                    kl 07:00 – 21:00 via telefon <br />
                                    <a href="tel:08520 252 00">
                                        08-520 252 00
                                    </a>
                                </p>
                                <p>
                                    <b>JOUR</b>
                                </p>
                                <p>
                                    Dygnet Jour – <a href="tel:0818 70 00">08-18 70 00</a>
                                </p>
                            </li>
                            <li>
                                {' '}
                                <a href="https://www.fastumdirekt.se/#/">
                                    Mina sidor - Fastum{' '}
                                </a>
                            </li>
                        </ul>
                    </Col>

                    <Col xs="12" sm="3">
                        <h5> Årsredovisning </h5>
                        <ul>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="icon"
                                />
                                <a href="/files/document/eas/arsredovisning-2023-Brf-Lyfta.pdf">
                                    2023
                                </a>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="icon"
                                />
                                <a href="/files/document/eas/arsredovisning-2022-Brf-Lyfta.pdf">
                                    2022
                                </a>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="icon"
                                />
                                <a href={Arsredovisning2021}>2021</a>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="icon"
                                />
                                <a href={Arsredovisning2020}>2020</a>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="icon"
                                />
                                <a href={Arsredovisning}>2019</a>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="icon"
                                />
                                <a href={RevisorsUttalande}>
                                    2019 revisorns utlåtande
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col xs="12" sm="3">
                        <h5> Nyhetsbrev</h5>
                        <ul>
                            <li>
                                {' '}
                                <Link to="/nyheter/senaste">Inlägg </Link>
                            </li>
                            <li>
                                {' '}
                                <NavLink to="/nyheter/prenumerera">
                                    Prenumerera
                                </NavLink>
                            </li>
                        </ul>
                    </Col>
                    <Col xs="12" sm="3">
                        <h5> Mäklare</h5>
                        <ul>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="icon"
                                />
                                <a href="/files/document/Information-till-maklare.pdf">
                                    Information till mäklare (Uppdaterad
                                    2024-03-07)
                                </a>
                            </li>
                            <li>
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="icon"
                                />
                                <a href="/files/document/slutbesked-fran-kommunen.pdf">
                                    Slutbesked från kommunen - Utlåtande Ikano
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>

                <hr />
                <p> © Brf Lyfta, 2020. Alla Rättigheter Reserverade. </p>
                <p>
                    {' '}
                    Powered by <a href="https://sw-tiger.com">sw-tiger.com</a>
                </p>
            </Container>
        </div>
    )
}
