import React from 'react'
import { Button, Navbar, NavbarBrand } from 'reactstrap'
import Logo from '../Files/Home/logo-120x80.jpg'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'

export default class TopMenu extends React.Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            isOpen: false,
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    closeMenu = () => {
        this.setState({
            isOpen: false,
        })
    }

    Logout = () => {
        axios(global.host + 'logout.php', {
            method: 'GET',
            withCredentials: true,
        }).then((response) => {
            this.props.logout()
        })
    }

    render() {
        var menu = ''
        if (this.props.admin) {
            menu = <AdminMenu logout={this.Logout} />
        }

        return (
            <div>
                <Navbar light expand="md" className="topnav-styling">
                    <NavbarBrand
                        href="#"
                        style={{
                            color: 'white',
                        }}
                    >
                        <img
                            className="img-fluid rounded"
                            style={{
                                height: '75px',
                            }}
                            src={Logo}
                            alt="FÖRENINGSHUSET"
                        />
                    </NavbarBrand>
                    <Nav navbar className="mr-auto menu">
                        {menu}
                    </Nav>
                </Navbar>
            </div>
        )
    }
}

function GetRoutes() {
    const routes = [
        {
            name: 'Startsida',
            linkname: '/admin',
        },
        {
            name: 'Skapa inlägg',
            linkname: '/admin/ny-post',
        },
        {
            name: 'Hantera inlägg',
            linkname: '/admin/hantera-inlagg',
        },
        {
            name: 'Skapa konto',
            linkname: '/admin/skapa-konto',
        },
        {
            name: 'Ladda upp filer',
            linkname: '/admin/ladda-upp-filer',
        },
        {
            name: 'Signerade filer',
            linkname: '/admin/signerade-filer',
        },
    ]
    return routes
}

function AdminMenu(props) {
    var routes = GetRoutes()
    let routeMenu = routes.map((data, index) => {
        return (
            <NavItem key={index}>
                <NavLink to={data.linkname} tag={Link}>
                    {' '}
                    {data.name}{' '}
                </NavLink>
            </NavItem>
        )
    })

    return (
        <React.Fragment>
            {routeMenu}
            <Button onClick={props.logout} size="sm" color="danger">
                {' '}
                Logga ut{' '}
            </Button>
        </React.Fragment>
    )
}
