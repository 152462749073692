import React, { useEffect, useState } from "react";
import { Container, Table } from 'reactstrap';

const SignedFiles = () => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(global.host + "file/s3/index.php", {
                    credentials: 'include', // Pass credentials with the request
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                setFiles(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []); // Empty dependency array to run the effect only once on initial render




    return (
        <div>
            <h1>Signerade filer</h1>
            {files.map((folder, index) => (
                <Container key={index}>

                    <h3>{folder.Category}</h3>
                    <Table key={index} >
                        <thead>
                            <tr>
                                <th>Filnamn</th>
                                <th>Skapad</th>
                            </tr>
                        </thead>
                        {folder.Files.map((file, index) => (

                            <tbody>

                                <tr >
                                    <td>
                                        <a href={`${global.host}file/s3/signed.php?key=${file.Key}`}>
                                            {file.Filename}
                                        </a>
                                    </td>
                                    <td>{file.LastModified}</td>
                                </tr>
                            </tbody>
                        ))}

                    </Table>
                </Container>

            ))}
        </div >
    );
}
export default SignedFiles;