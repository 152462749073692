import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { useRouteMatch, Redirect, Switch, Route } from 'react-router-dom'
import Login from './Login'
import NewsPublisher from './NewsPublisher'
import Register from './Register'
import PostHandler from './PostHandler'
import SignedFiles from './SignedFiles'
import UploadFilePage from "./UploadFilePage"

export default function Admin(props) {
    let { path, url } = useRouteMatch()
    const authenticated = props.authenticated
    return (
        <Container>
            <Row>
                <Col>
                    <Switch>
                        <Route exact path={`${url}/logga-in`}>
                            <Login login={props.login} />
                        </Route>
                        <PrivateRoute
                            admin={authenticated}
                            exact
                            path={`${url}/`}
                        >
                            <AdminHome />
                        </PrivateRoute>

                        <PrivateRoute
                            admin={authenticated}
                            path={`${url}/ny-post`}
                        >
                            <NewsPublisher />
                        </PrivateRoute>
                        <PrivateRoute
                            admin={authenticated}
                            path={`${url}/hantera-inlagg`}
                        >
                            <PostHandler />
                        </PrivateRoute>

                        <PrivateRoute
                            admin={authenticated}
                            path={`${url}/skapa-konto`}
                        >
                            <Register />
                        </PrivateRoute>
                        <PrivateRoute
                            admin={authenticated}
                            path={`${url}/signerade-filer`}
                        >
                            <SignedFiles />
                        </PrivateRoute>
                        <PrivateRoute
                            admin={authenticated}
                            path={`${url}/ladda-upp-filer`}
                        >
                            <UploadFilePage />
                        </PrivateRoute>
                        <Route path="/*" component={Login} />
                    </Switch>
                    <hr />
                </Col>
            </Row>
        </Container>
    )
}

function AdminHome() {
    return (
        <div>
            <h1> Du är nu inloggad! </h1>
            <p>Välkommen till administrationssidan.</p>
        </div>
    )
}

function PrivateRoute({ admin, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                admin ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/admin/logga-in',
                            state: {
                                from: location,
                            },
                        }}
                    />
                )
            }
        />
    )
}
