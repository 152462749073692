import React from "react";
import axios from "axios";
import { Spinner, Container} from 'reactstrap';
import PostsV2 from './PostV2';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasLoaded: false,
            posts: ""
        };
    }

    componentDidMount() {
        axios.get(global.host + "posts.php?amount=1&includeContent=true").then(response => {
            if (response.data.length > 0) {
                this.setState({
                    posts: response.data,
                    hasLoaded: true
                });
            }

        });
    }

    render() {
        if (this.state.hasLoaded) {
            return (
                    <PostsV2 posts = {this.state.posts} />
            );
        } else {
            return (
                <Container>
                <Spinner  style={{
                    width: '3rem',
                    height: '3rem'
                }}color="primary" />
                </Container>
            );
        }
    }
}

export default Dashboard;
