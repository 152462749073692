import React from "react";
import { Container, Row } from 'reactstrap';

export default function UploadFile({ setFile, setFileName }) {

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            readFileAsBase64(selectedFile)
                .then(base64String => {
                    console.log(base64String);
                    setFile(base64String);
                    setFileName(selectedFile.name);
                })
                .catch(error => console.error('Error reading file:', error));
        }
    };


    function readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result?.toString()?.split(',')[1];
                if (base64String) {
                    return resolve(base64String);
                } else {
                    reject(new Error('Failed to read file as base64.'));
                }
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    return (
        <Container>
            <Row>
                <h1>Ladda upp filer för signering</h1>
            </Row>
            <Row>
                <h5>Max 8 Mb i storlek.</h5>

            </Row>
            <Row>
                <input
                    type='file'
                    id='file'
                    name='file'
                    accept='application/pdf'
                    onChange={handleFileChange}
                />
            </Row>
        </Container>
    );
}
