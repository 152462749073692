import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

export function PdfFile(props){

    return (
        <React.Fragment>
        <FontAwesomeIcon icon={faFilePdf} className="icon"/>
            <a href={props.filepath}>
            {props.filename}
        </a>
        </React.Fragment>)
}