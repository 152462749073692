import React from 'react'
import { Route, Link, NavLink } from 'react-router-dom'
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHome,
    faLock,
    faNewspaper,
    faPhoneAlt,
    faUsers,
    faBuilding,
    faInfoCircle,
    faFile,
} from '@fortawesome/free-solid-svg-icons'

const SideNavItem = (props) => {
    return (
        <Route
            render={({ location, history }) => (
                <NavItem
                    onSelect={(selected) => {
                        const to = '/' + selected
                        if (location.pathname !== to) {
                            history.push(to)
                        }
                    }}
                    eventKey={props.eventKey}
                    onClick={props.onClick}
                >
                    <NavIcon>
                        <FontAwesomeIcon
                            icon={props.icon}
                            className={props.className}
                        />
                    </NavIcon>
                    <NavText>{props.text}</NavText>
                </NavItem>
            )}
        />
    )
}

export default function Leftmenu(props) {
    return (
        <React.Fragment>
            <SideNav onToggle={props.onToggle} expanded={props.expanded}>
                <SideNav.Toggle
                    expanded={props.expanded}
                    onClick={() => props.onToggle}
                />
                <SideNav.Nav defaultSelected="home">
                    <SideNavItem
                        text="Hem"
                        eventKey=""
                        icon={faHome}
                        className="faMenuIcons"
                        onSelect={props.onSelect}
                        onClick={() => {
                            if (props.expanded) props.onToggle()
                        }}
                    />

                    <SideNavItem
                        text="Nyheter"
                        eventKey="nyheter/senaste"
                        icon={faNewspaper}
                        className="faMenuIcons"
                        onSelect={props.onSelect}
                        onClick={() => {
                            if (props.expanded) props.onToggle()
                        }}
                    />

                    <SideNavItem
                        text="Stadgar"
                        eventKey="stadgar"
                        icon={faBuilding}
                        className="faMenuIcons"
                        onSelect={props.onSelect}
                        onClick={() => {
                            if (props.expanded) props.onToggle()
                        }}
                    />

                    <SideNavItem
                        text="Styrelsen"
                        eventKey="styrelsen"
                        icon={faUsers}
                        className="faMenuIcons"
                        onSelect={props.onSelect}
                        onClick={() => {
                            if (props.expanded) props.onToggle()
                        }}
                    />
                    <SideNavItem
                        text="Dokument"
                        eventKey="dokument"
                        icon={faFile}
                        className="faMenuIcons"
                        onSelect={props.onSelect}
                        onClick={() => {
                            if (props.expanded) props.onToggle()
                        }}
                    />
                    <SideNavItem
                        text="Kontakta oss"
                        eventKey="kontakta-oss"
                        icon={faPhoneAlt}
                        className="faMenuIcons"
                        onSelect={props.onSelect}
                        onClick={() => {
                            if (props.expanded) props.onToggle()
                        }}
                    />
                    <SideNavItem
                        text="Administrering"
                        eventKey="admin"
                        icon={faLock}
                        className="faMenuIcons"
                        onSelect={props.onSelect}
                        onClick={() => {
                            if (props.expanded) props.onToggle()
                        }}
                    />
                </SideNav.Nav>
            </SideNav>
        </React.Fragment>
    )
}
