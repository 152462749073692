import React from 'react'
import { Button, ButtonGroup, Row, Col, Container } from 'reactstrap'
import { Link, useRouteMatch, Switch, Route } from 'react-router-dom'
import PostsSummary from './PostsSummary'
import Subscribe from './Subscribe'
import PostView from './PostView'
import { Helmet } from 'react-helmet'

export default function Nyheter() {
    let { path, url } = useRouteMatch()
    return (
        <Container>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Nyheter | Brf Lyfta </title>
                <meta
                    name="description"
                    content="Läs de senaste nyheterna inom föreningen, det går också bra att prenumera för att få mejl skickat när ny information dyker upp."
                />
            </Helmet>
            <Row>
                <Col xs="12">
                    <ButtonGroup size="sm">
                        <Button
                            tag={Link}
                            to={`${url}/senaste`}
                            className="btn-top-menu"
                        >
                            Nyheter
                        </Button>
                        <Button
                            tag={Link}
                            to={`${url}/prenumerera`}
                            className="btn-top-menu"
                        >
                            Prenumerera
                        </Button>
                    </ButtonGroup>
                    <Switch>
                        <Route
                            path={`${url}/senaste`}
                            exact
                            component={PostsSummary}
                        />
                        <Route
                            path={`${url}/prenumerera`}
                            component={Subscribe}
                        />
                        <Route path={`${url}/inlagg`} component={PostView} />
                        <Route path={`${url}*`} component={PostsSummary} />
                    </Switch>
                    <hr />
                </Col>
            </Row>
        </Container>
    )
}
