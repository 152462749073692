import React from 'react'
import { Button } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import {
    Alert,
    Row,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap'
import axios from 'axios'

export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            email: '',
            password: '',
            errMsg: '',
        }
    }

    componentDidMount() {
        document.title = 'Logga in' + global.hostname
        axios
            .get(global.host + 'login.php', {
                withCredentials: true,
            })
            .then((response) => {
                if (response.status === 200) {
                    this.props.login()
                    this.setState({
                        redirect: true,
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    onInput = (event) => {
        var inputId = event.target.id
        var inputValue = event.target.value
        this.setState({
            [inputId]: inputValue,
        })
    }

    onSubmit = (event) => {
        event.preventDefault()

        axios
            .post(
                global.host + 'login.php',
                {
                    email: this.state.email,
                    password: this.state.password,
                },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    this.props.login()
                    this.setState({
                        redirect: true,
                    })
                }
            })
            .catch((error) => {
                console.log(error.response.data.message)
                this.setState({
                    errMsg: error.response.data.message,
                })
            })
    }
    render() {
        var loginMessage = ''
        if (this.state.errMsg) {
            loginMessage = <Alert color="danger">{this.state.errMsg}</Alert>
        }

        if (this.state.redirect) {
            return <Redirect push to="/admin" />
        } else {
            return (
                <Container>
                    <Row>
                        <Col
                            sm="12"
                            lg={{
                                size: 6,
                                offset: 3,
                            }}
                        >
                            <h1> Administration </h1>
                            <Form onSubmit={this.onSubmit}>
                                <FormGroup>
                                    <Label for="email">Epost</Label>
                                    <Input
                                        type="email"
                                        value={this.state.email}
                                        onChange={this.onInput}
                                        name="email"
                                        id="email"
                                        placeholder="epost@hotmail.com"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Lösenord</Label>
                                    <Input
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.onInput}
                                        name="password"
                                        id="password"
                                        placeholder="***********"
                                    />
                                </FormGroup>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={false}
                                >
                                    Logga in
                                </Button>
                            </Form>

                            {loginMessage}
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}
