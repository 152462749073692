import React, { useState } from 'react'
import { Container, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import FAQ from '../../Files/FAQ.json'

export default function ContactForm(props) {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [apartmentNbr, setApartmentNbr] = useState('')
    const [formSubmitted, setSubmitted] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault()
        setSubmitted(true)
        axios(global.host + 'contact-us.php', {
            method: 'POST',
            data: {
                email: email,
                apartmentNbr,
                message: message,
            },
        })
            .then((res) => {
                //console.log(res)
            })
            .catch((error) => {
                //console.log(error)
            })
    }
    if (!formSubmitted) {
        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kontakta oss | Brf Lyfta </title>
                    <meta
                        name="description"
                        content="Du kan självklart komma i kontakt med oss och ställa din fråga till föreningen. Använd kontaktformuläret nedan eller skicka ett mejl direkt till info@brflyfta.se"
                    />
                </Helmet>
                <h1>Kontakta oss</h1>
                <p> Här kan du ställa en fråga till styrelsen. </p>

                <h4>Vanligt förekommande frågor</h4>
                {FAQ.map((faq, index) => (
                    <div>
                        {' '}
                        <b>
                            {index + 1}. {faq.question}
                        </b>
                        <p>{faq.answer}</p>
                    </div>
                ))}
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="email">Epost</Label>
                        <Input
                            type="email"
                            value={email}
                            name="email"
                            id="email"
                            placeholder=""
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="apartmentNbr">
                            Lägenhetsnummer (6 siffror)
                        </Label>
                        <Input
                            type="text"
                            minLength="6"
                            value={apartmentNbr}
                            name="apartmentNbr"
                            id="apartmentNbr"
                            placeholder=""
                            onChange={(e) => setApartmentNbr(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="textarea">Meddelande</Label>
                        <Input
                            type="textarea"
                            rows="10"
                            name="text"
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </FormGroup>
                    <Button
                        color="success"
                        disabled={
                            email && message && apartmentNbr ? false : true
                        }
                        type="submit"
                    >
                        Skicka
                    </Button>
                </Form>
            </Container>
        )
    } else {
        return (
            <Container>
                <h1>Tack för ditt mejl!</h1>
                <p>
                    Vi hör av oss så fort vi kan.
                    <br />
                    Mvh
                    <br />
                    Din styrelse
                </p>
            </Container>
        )
    }
}
