import React from 'react'
import { Container, Spinner } from 'reactstrap'
import AttachedFiles from './AttachedFiles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCalendar } from '@fortawesome/free-solid-svg-icons'
import draftToHtml from 'draftjs-to-html'
import { Helmet } from 'react-helmet'
class PostView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasLoaded: false,
            posts: props.posts,
        }
    }

    componentDidMount() {
        this.setState({
            hasLoaded: true,
        })
    }

    render() {
        if (!this.state.hasLoaded) {
            return (
                <div>
                    <Spinner
                        style={{
                            width: '3rem',
                            height: '3rem',
                        }}
                        color="primary"
                    />
                </div>
            )
        } else {
            return this.state.posts.map((post, index) => (
                <Container
                    key={index}
                    style={{ paddingTop: '30px', paddingBottom: '30px' }}
                >
                    <Helmet>
                        <title>{post.title} | Brf Lyfta </title>
                    </Helmet>
                    <h2 className="text-uppercase"> {post.title} </h2>
                    <p>
                        <FontAwesomeIcon icon={faCalendar} color="orange" />{' '}
                        {post.createdAt}{' '}
                        <FontAwesomeIcon icon={faUser} color="orange" />{' '}
                        <b>{post.author}</b>
                    </p>

                    <AttachedFiles postId={post.id} />

                    <div
                        dangerouslySetInnerHTML={{
                            __html: draftToHtml(post.content),
                        }}
                    />
                </Container>
            ))
        }
    }
}

export default PostView
