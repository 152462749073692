import React from 'react'
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import './App.css'
import ScrollToTop from './Components/ScrollToTop'
import { BottomMenu } from './Menu/BottomMenu'
import LeftMenu from './Menu/LeftMenu'
import TopMenu from './Menu/TopMenu'
import Admin from './Pages/Admin/Admin'
import Committee from './Pages/Committee/Committee'
import Dokument from './Pages/Dokument'
import Foreningen from './Pages/Foreningen/Foreningen'
import { Home } from './Pages/Home'
import Kontakt from './Pages/Kontakt/Kontakt'
import Nyheter from './Pages/Nyheter/Nyheter'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            admin: false,
            expanded: false,
        }
    }

    login = () => {
        this.setState({
            admin: true,
        })
    }

    onToggle = (expanded) => {
        this.setState({
            expanded: expanded,
        })
    }

    logout = () => {
        this.setState({
            admin: false,
        })
    }

    render() {
        const marginleft = this.state.expanded ? 240 : 64
        return (
            <Router>
                <div className="sidenav">
                    <LeftMenu
                        expanded={this.state.expanded}
                        onToggle={this.onToggle}
                    />
                </div>
                <ScrollToTop />
                <div
                    style={{
                        marginLeft: marginleft + 'px',
                    }}
                >
                    <TopMenu logout={this.logout} admin={this.state.admin} />
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/nyheter" component={Nyheter} />
                        <Route path="/styrelsen" component={Committee} />
                        <Route path="/stadgar" component={Foreningen} />
                        <Route path="/dokument" component={Dokument} />
                        <Route path="/kontakta-oss" component={Kontakt} />
                        <Route path="/admin">
                            <Admin
                                authenticated={this.state.admin}
                                login={this.login}
                            />
                        </Route>
                        <Route path="/*" component={NoMatch} />
                    </Switch>
                    <BottomMenu />
                </div>
            </Router>
        )
    }
}

function NoMatch() {
    return (
        <div>
            <h2>404 Sidan kunde inte hittas!</h2>
            Vi kunde inte hitta sidan du söker efter.
        </div>
    )
}

export default App
