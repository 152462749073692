import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'

export default function Foreningen() {
    let { path, url } = useRouteMatch()
    return (
        <Container>
            <Row>
                <Col xs="12" md="8">
                    <Switch>
                        <Route exact path={`${url}/`} component={Stadgar} />
                    </Switch>
                    <hr />
                </Col>
            </Row>
        </Container>
    )
}

function Stadgar() {
    return (
        <Container>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Stadgar | Brf Lyfta </title>
                <meta name="description" content="Föreningens stadgar." />
            </Helmet>
            <h1>Stadgar</h1>
            <FontAwesomeIcon icon={faFilePdf} className="icon" />
            <a href="/files/document/stadgar.pdf">Brf Lyfta Stadgar </a>
        </Container>
    )
}
