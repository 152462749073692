import React from "react";
import { Button, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

const AddSigners = ({ formData, setFormData, handleSubmit, disabled }) => {


    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const { signers } = formData;
        signers[index][name] = value;
        setFormData({ ...formData, signers });
    };

    const handleAddSigner = () => {
        setFormData({
            ...formData,
            signers: [...formData.signers, { first_name: "", last_name: "", address: "" }]
        });
    };

    const handleRemoveSigner = (index) => {
        const { signers } = formData;
        signers.splice(index, 1);
        setFormData({ ...formData, signers });
    };

    return (
        <>
            <Form className="mt-4" onSubmit={handleSubmit}>
                {formData.signers.map((signer, index) => (
                    <React.Fragment key={index}>
                        <FormGroup  >
                            <Label>Namn (Signerare {index + 1})</Label>
                            <Input
                                type="text"
                                name={`first_name`}
                                value={signer.first_name}
                                onChange={(e) => handleChange(index, e)}
                            />
                            {formData.errors[index] && formData.errors[index].first_name && (
                                <div className="error">{formData.errors[index].first_name}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>Efternamn</Label>
                            <Input
                                type="text"
                                name={`last_name`}
                                value={signer.last_name}
                                onChange={(e) => handleChange(index, e)}
                            />
                            {formData.errors[index] && formData.errors[index].last_name && (
                                <div className="error">{formData.errors[index].last_name}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="email"
                                name={`address`}
                                value={signer.address}
                                onChange={(e) => handleChange(index, e)}
                            />
                            {formData.errors[index] && formData.errors[index].address && (
                                <div className="error">{formData.errors[index].address}</div>
                            )}
                        </FormGroup>
                        <Button className="mb-4" color="danger" onClick={() => handleRemoveSigner(index)}>
                            Ta bort signerare {index + 1}
                        </Button>
                    </React.Fragment>
                ))}
                <br />
                <Container>

                    <Row>
                        <Button color="success" className="mt-3" onClick={handleAddSigner}>
                            Lägg till signerare
                        </Button>

                    </Row>

                    <Row>
                        <Button color="primary" block type="submit" className="mt-3" disabled={disabled}>
                            Ladda upp fil
                        </Button>
                    </Row>

                </Container>

            </Form>
        </>
    );
};

export default AddSigners;
