import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import CommiteeMembers from './CommitteeMembers.json'

export default function Committee() {
    let { path, url } = useRouteMatch()
    return (
        <Container>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Styrelsen | Brf Lyfta </title>
                <meta
                    name="description"
                    content="En lista på alla styrelsemeddlemar i föreningen."
                />
            </Helmet>
            <Row>
                <Col xs="12" md="8">
                    <Switch>
                        <Route
                            exact
                            path={`${url}`}
                            component={Members}
                        ></Route>
                    </Switch>
                    <hr />
                </Col>
            </Row>
        </Container>
    )
}

function CommiteeTitle(title) {
    return <h4>{title}</h4>
}

function TitleMembers(members) {
    return members.map((person, index) => {
        return <p key={index}>{person.name}</p>
    })
}

function Members() {
    const title = 'Styrelsen'

    var content = CommiteeMembers.map((member, index) => {
        return (
            <Col xs="12" md="6" key={index}>
                {CommiteeTitle(member.title)}
                {TitleMembers(member.persons)}
            </Col>
        )
    })

    return (
        <div>
            <h1>{title}</h1>
            <Row>{content}</Row>
        </div>
    )
}
