import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import {
    Alert,
    Form,
    FormGroup,
    Label,
    Container,
    Col,
    Row,
    Button,
    Input,
} from 'reactstrap'
import axios from 'axios'

export default function Subscribe() {
    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState(false)
    const [email, setEmail] = useState('')
    const [resMsg, setResMsg] = useState('')

    const handleSubmit = (event) => {
        event.preventDefault()
        window.scrollTo(0, 0)
        setSubmitted(true)
        axios(global.host + 'news/subscribe.php', {
            method: 'POST',
            data: {
                email: email,
            },
        })
            .then((response) => {
                setResMsg(response.data.message)
                setSubmitError(response.data.error)
            })
            .catch((error) => {
                setResMsg('Något gick fel, försök igen senare.')
                setSubmitError(true)
            })
    }
    return (
        <Container fluid className="fluid footer-email">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Prenumerera på nyhetsflöde | Brf Lyfta </title>
                <meta
                    name="description"
                    content="Prenumerera nu och håll dig uppdaterad med styrelsens senaste nyheter."
                />
            </Helmet>
            <Row>
                <Col
                    sm="12"
                    md={{
                        size: 6,
                        offset: 3,
                    }}
                >
                    <h3 className="text-center">
                        Få Senaste Uppdateringar Via Mail
                    </h3>
                    <p className="text-center">
                        Missa inte de senaste nyheterna — Prenumerera och få de
                        senaste nyheterna direkt i din inkorg. Tjänsten är helt
                        gratis.
                    </p>
                </Col>
            </Row>

            {submitted ? (
                <Alert
                    color={submitError ? 'danger' : 'success'}
                    isOpen={submitted}
                >
                    {resMsg}
                </Alert>
            ) : (
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="email">E-postadress</Label>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            id="email"
                            placeholder="epost@hotmail.com"
                        />
                    </FormGroup>
                    <Button type="submit" color="success">
                        Prenumerera
                    </Button>
                </Form>
            )}
        </Container>
    )
}
