import React from 'react'
import queryString from 'query-string'
import { Spinner } from 'reactstrap'
import axios from 'axios'
import PostV2 from './PostV2'
class PostView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasLoaded: false,
            posts: '',
        }
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        var query = this.props.location.search
        axios
            .get(global.host + 'posts.php' + query, {
                params: {
                    includeContent: true,
                    amount: 1,
                    ID: values.ID,
                },
            })
            .then((response) => {
                this.setState({ posts: response.data, hasLoaded: true })
            })
    }

    render() {
        if (!this.state.hasLoaded) {
            return (
                <div>
                    <Spinner
                        style={{
                            width: '3rem',
                            height: '3rem',
                        }}
                        color="primary"
                    />
                </div>
            )
        } else {
            return <PostV2 posts={this.state.posts} />
        }
    }
}

export default PostView
