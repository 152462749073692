import React from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'

class AttachedFiles extends React.Component {
    constructor() {
        super()
        this.state = {
            hasLoaded: false,
            files: [],
        }
    }

    componentDidMount() {
        axios
            .get(global.host + 'postfiles.php?postId=' + this.props.postId)
            .then((response) => {
                this.setState({ files: response.data })
            })
    }

    render() {
        var listOfFiles = this.state.files.map((file, index) => {
            return (
                <div key={index}>
                    <FontAwesomeIcon icon={faPaperclip} color="black" />
                    <a href={global.hostFiles + '' + file.filename}>
                        {file.filename}
                    </a>
                </div>
            )
        })
        return <div>{listOfFiles}</div>
    }
}
export default AttachedFiles
